import { Splide } from "@splidejs/splide";

//product carousel
class filterBannerCarousel extends HTMLElement {
  constructor() {
      super();
  }

  connectedCallback() {
    var main = new Splide(this, {
      pagination: false,
      arrows     : false,
      perMove    : 1,
      autoWidth  : true,
      gap        : '1rem',
      arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
    });
    main.mount()
  }

}

customElements.define('filter-banner-carousel', filterBannerCarousel);
